@mixin ff-a_otf_chugo{
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin ff-a_otf_futogo {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif;
  font-weight: 800;
  font-style: normal;
}

// フォントサイズ
$fontSizeList: 62%, 70%, 77%, 85%, 93%, 100%, 108%, 116%, 124%, 131%, 139%, 147%, 154%, 162%, 170%, 177%, 185%, 193%, 200%, 208%, 216%, 224%, 231%, 239%, 247%, 254%, 262%, 270%, 277%, 285%, 293%, 300%, 308%;
@mixin fz($size) {
  @if $size < 8 {
    $size: 8;
  }
  @if $size > 40 {
    $size: 40;
  }
  font-size: nth($fontSizeList, $size - 7);
}

// メディアクエリ
@mixin mq($breakpoint: md) {
	@media screen and (max-width: $breakpoint) {
		@content;
	}
}

@mixin flex_row {
  display: flex;
  flex-wrap: wrap;
}

// スプライト画像書き出し
@mixin sprites($map, $map-item, $isCommon:false) {
  @if $isCommon {
    background-image: sprite-url($map);
    background-repeat: no-repeat;
  } @else {
    width: round(image-width(sprite-file($map, $map-item)));
    height: round(image-height(sprite-file($map, $map-item)));
    $pos-y: round(nth(sprite-position($map, $map-item), 2));
    background-position: 0 $pos-y;
  }
}

@mixin anim($sec,$prop:all) {
	-webkit-transition: $prop #{$sec}ms;
	-moz-transition: $prop #{$sec}ms;
	-ms-transition: $prop #{$sec}ms;
	-o-transition: $prop #{$sec}ms;
	transition: $prop #{$sec}ms;
}

@mixin absolute-fit {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

@mixin absolute-center {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@function relativeSize($num, $base: $mobileBaseWidth) {
	@return $num / $base * 100%;
}

@import "core/_import";

$mobileBaseWidth:640;

// ========================================
// ブレイクポイント
// ========================================

$sm: 480px;
$md: 1023px;
$lg: 1280px;

// ========================================
// コンテンツの最大幅
// ========================================

$CONTENT_WIDTH: 1024px;

// ========================================
// 各見出しの文字サイズ
// ========================================

$sp_visual_area_title:8vw;


// ========================================
// common
// ========================================

body{
  margin: 0;
  padding: 0;
  @include ff-a_otf_chugo;
  background: #F0F5F0;
}

img{
  width:100%;
}

a{
  color:#000;
  text-decoration: none;
}

.preload_image{
  display: none;
}

@media screen and (min-width:$sm) {
  .forpc{
    display: block;
  }
  .forsp{
    display: none;
  }
}

@media screen and (max-width:$sm) {
  .forpc{
    display: none;
  }
  .forsp{
    display: block;
  }
}

header{
  width:100%;
  position: relative;
  z-index: 9999;
  h1{
    position: fixed;
    // top:50%;
    top:20px;
    left:50%;
    // text-align: center;
    z-index: 100;

    transform: translateY(0%) translateX(-50%);
    line-height: 1;
    font-size: 0.8em;
    border-bottom: 2px solid #000;
    padding-bottom: 0.4em;
    letter-spacing: 0.05em;
  }
  .nav_container{
    position: fixed;
    @include ff-a_otf_futogo;
    // width: 50%;
    font-size: 1.1em;
    top:50%;
    right:0;
    transform: translateY(-50%) translateX(0);
    // width:100%;
    // height: 100vh;

    @include mq($sm){
      display: none;
      width: 100%;
      height: 100vh;
      background: #fff;
    }
    &.active{
      display: block;
    }
    ul{
      // position: absolute;
      // top:50%;
      // right:0;
      width: auto;
      // transform: translateY(-50%) translateX(0);
      // margin: 0 20px;
      // text-align: right;
      // margin-right: 20px;
      // letter-spacing: 0.1em;
      @include mq($sm){
        position: absolute;
        top:50%;
        right:0;
        transform: translateY(-50%) translateX(0);
      }
      li{
        @include mq($sm){
          font-size: 6vw;
          margin-bottom: 2vw
        }
      }
      a{
        display: block;
        padding-right: 20px;
        &:hover{
          background: #FFF;
        }
      }
    }
  }
  address{
    position: fixed;
    bottom:50%;
    left:20px;
    transform: translateY(50%) translateX(0);
    z-index: 1000;
    // width: 100px;
    li{
      width: 30px;
      // display: inline;
      // float: left;
      // margin-right: 10px;
      @include mq($sm){
        float: left;
        margin-right: 10px;
      };
      &:last-child{
        margin:0;
      }
    }
    @include mq($sm){
      bottom:17px;
      transform: translateY(0) translateX(0);
    };
  }
}

.menu-toggle{
  display: none;
  width:36px;
  height:36px;
  z-index: 9999;
  position: fixed;
  bottom:20px;
  right:20px;
  @include mq($sm){
    display: block;
  }
  &.active{
    .line{
      transform: rotate(45deg);
      top:48%;
    }
    &:after{
      transform: rotate(-45deg);
      bottom:48%;
    }
  }
  .line{
    @include anim(200);
    position: absolute;
    width: 100%;
    height:2px;
    background: #000000;
    top:30%;
  }
  &:after{
    content: "";
    @include anim(200);
    position: absolute;
    width: 100%;
    height:2px;
    background: #000000;
    bottom:30%;
  }
}

.visual_area{
  width: 100%;

  .visual{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index:999;
  }
  .title{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index:9000;
    @include titlebg;
    background-position: center center;
    background-size:contain;
    background-repeat: no-repeat;
    @include mq($sm){
      background-image:url("/assets/img/image-title-sp.png");
    };
  }
}

.contents_area.front{
  margin-top:100vh;
}

.contents_area.single{
  margin-top:10vh;
  .bg{
    background-color: rgba(255, 255, 255,1) !important;
  }
  .header{
    h2{
      br{
        @include mq($sm){
          display: none;
        }
      }
    }
  }
}

.contents_area{
  position: relative;
  width: 100%;
  
  // margin-bottom: 100vh;
  a{
    text-decoration:underline;
  }
  .content{
    padding-top: 1vw;
    width: 60%;
    margin:0 auto 20vw;
    @include mq($sm){
      width: 84%;
      margin: 0 auto 30vw;
    };
    .bg{
      position: absolute;
      top:0%;
      left:0%;
      width:100%;
      height:100%;
      // margin: 8px;
      // background-color: #fff;
      z-index: 1;
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      background-color: rgba(255, 255, 255,0.5);
      @include mq($sm){
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        background-color: rgba(255, 255, 255,0.5);
      };
      // opacity: 0.3;
    }
    .header{
      overflow: hidden;
      position: relative;
      // width: auto;
      display: inline-block;
      padding: 3vw 2.4vw 1.8vw;
      // z-index: 100;

      // background: #fff;
      margin-bottom: 3vw;
      @include mq($sm){
        margin-bottom: 5vw;
        adding:7vw 5vw 3.3vw;
      }

      // background-image:url("/img/bg-content.png");
      .caption{
        position: relative;
        z-index: 100;
        display: block;
        font-size: 1.2vw;
        line-height: 1;
        margin-bottom: .7vw;
        @include mq($sm){
          font-size: 3vw;
          margin-bottom: 2vw;
        }
      };
      h2{
        position: relative;
        z-index: 100;
        @include ff-a_otf_futogo;
        display: inline-block;
        // padding: 3vw 2.4vw 1.8vw;
        font-size: 2.3vw;
        @include mq($sm){
          font-size: 5.5vw;
        }
      }

    }
    .text{
      // background: #fff;
      overflow: hidden;
      position: relative;
      z-index: 10;
      padding: 3vw 2.4vw;
      margin-bottom: 3vw;
      @include mq($sm){
        font-size: 4.5vw;
        padding:8vw 5vw 3.3vw;
        margin-bottom: 5vw;
      }
      .m-header{
        position: relative;
        z-index: 10;
        font-size: 1.8vw;
        margin-bottom: 1.5vw;
        @include mq($sm){
          font-size: 4.5vw;
          margin-bottom: 4vw;
        }
      }
      .cont{
        position: relative;
        z-index: 10;
        text-align: justify;
        font-size: 1.4vw;
        line-height: 2.4;
        margin-bottom:2.4vw;
        @include mq($sm){
          font-size: 4vw;
          line-height: 2;
        }
        &:last-child{
          margin:0;
        }
      }
      .contact_list{
        position: relative;
        z-index: 10;
        .list_header{
          margin-top:1vw;
          font-size: 1.1vw;
          @include mq($sm){
            font-size: 4vw;
            line-height: 2;
            margin-top:5vw;
          }
        }
        .list_content{
          margin-top:0.6vw;
          .txtbox{
            width: 100%;
            padding:15px;
            box-sizing: border-box;
            border:1px solid #ccc;
          }
          .txtarea{
            width: 100%;
            padding:15px;
            box-sizing: border-box;
            border:1px solid #ccc;
          }
        }
      }
      .caution{
        margin-top:1vw;
        position: relative;
        z-index: 10;
        @include mq($sm){
          font-size: 4vw;
          line-height: 2;
          margin-top:5vw;
        }
      }
      .submit_btn{
        margin-top:1vw;
        margin-bottom:1vw;
        border:none;
        padding:15px 30px;
        font-size: 1.2vw;
        background: #fff;
        border:1px solid #000;
        // color:#;
        @include mq($sm){
          font-size: 4vw;
          margin-top:5vw;
          margin-bottom:5vw;
        }
      }
      p,.wpcf7{
        position: relative;
        z-index: 10;
      }
      .wpcf7 .wpcf7-response-output {
        margin: 10px 0 0;
        padding: 8px 35px 8px 14px;
        // color:$nega_txt_color;
        background-color: #FFF;
        // text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        margin-bottom: 20px;
      }
      .wpcf7 .wpcf7-not-valid-tip{
        font-size: 14px;
        border:none;
      }
      .wpcf7 .wpcf7-validation-errors {
        color: #B94A48;
        background-color: #FFF;
        border:none;
        // border: 1px solid #EED3D7;
      }
      .wpcf7 .wpcf7-mail-sent-ok {
        color: #3A87AD;
        background-color: #FFF;
        border:none;
        // border: 1px solid #BCE8F1;
      }
      .wpcf7 form .wpcf7-response-output {
        // margin: 2em 0.5em 1em;
        // padding: 0.2em 1em;
        margin-top:1vw;
        border:none;
        // border-left: 2px solid #46b450 !important;
        border:none !important;
        background: #FFDEDE;
      }
        
      .wpcf7 form.init .wpcf7-response-output {
          display: none;
      }
        
      .wpcf7 form.sent .wpcf7-response-output {
        // border-left: 2px solid #46b450 !important;
        border:none !important;
      }
        
      .wpcf7 form.failed .wpcf7-response-output,
      .wpcf7 form.aborted .wpcf7-response-output {
        border:none !important;
      }
        
      .wpcf7 form.spam .wpcf7-response-output {
        border:none !important;
      }
        
      .wpcf7 form.invalid .wpcf7-response-output,
      .wpcf7 form.unaccepted .wpcf7-response-output {
        border:none !important;

      }
      .s-header{
        font-size: 1.6vw;
        margin-bottom: 1.5vw;
        @include mq($sm){
          font-size: 4.5vw;
          margin-bottom: 4vw;
        }
      }
      .s-header,.casesutudies,.detail-link,.members,.topics,.nopost{
        position: relative;
        z-index: 10;
      }
      .casesutudies{
        width: 100%;
        margin-bottom: 1.5vw;
        @include flex_row;
        @include clearfix();
        .casestudy{
          width: 47%;
          // float: left;
          margin-bottom:2vw;
          @include mq($sm){
            // float:none;
            width: 100%;
            margin-bottom:5vw;
          }
          &:nth-child(even){
            margin-left:6%;
            @include mq($sm){
              margin-left:0;
            }
          }
          a{
            text-decoration: none;
          }
          .thumb{
            img{
              vertical-align: bottom;
            }
            margin-bottom:1.4vw;
            @include mq($sm){
              margin-bottom:3vw;
            }
          }
          .affiliation{
            font-size: 0.8vw;
            line-height: 1.8;
            margin-bottom:0.7vw;
          }
          .ss-header{
            font-size: 1.2vw;
            @include mq($sm){
              font-size:4vw;
            }
          }
        }
      }
      .detail-link{
        a{
          text-align: center;
          text-decoration: none;
          display: block;
          width: 100%;
          border:1px solid #000;
          padding-top: 12px;
          padding-bottom: 12px;
          // padding: 300px auto !important;
          &:hover{
            background: #FFF;
          }
          .recruit{
            width:50%;
            padding:3vw 0;
            @include mq($sm){
              width:80%;
            }
          }
        }
      }



      .definition{
        position: relative;
        z-index: 10;
        width: 100%;
        font-size: 1.4vw;
        .d_header{
          width:9em;
          float: left;
          @include mq($sm){
            font-size: 4vw;
            width: 30%;
          };
        }
        .d_content{
          margin-left: 11em;
          margin-bottom: 1em;
          line-height: 1.8;
          .rice{
            font-size: 0.8em;
            line-height: 1.4;
          }
          @include mq($sm){
            margin-left:35%;
            font-size: 4vw;
            margin-bottom: 0.5em;
          };
        }
      }

      .members{
        .member{
          .member-title{
            font-size: 0.8vw;
          }
          .member-name{
            font-size: 1.8vw;
            margin-bottom: 1.4vw;
            @include mq($sm){
              font-size: 4.5vw;
              margin-bottom: 3vw;
          }
          }
          .member-text{
            font-size: 1.2vw;
            margin-bottom: 2.6vw;
            line-height: 2;
            @include mq($sm){
                font-size: 4vw;
                margin-bottom: 6vw;
            }
          }
        }
        
      }
      .topics{
        .topic{
          .topic-date{
            font-size: 0.8vw;
            margin-bottom: 0.5vw;
          }
          .topic-title{
            font-size: 1.2vw;
            line-height: 1.8;
            margin-bottom: 2.6vw;
            font-weight: normal;
            @include mq($sm){
              font-size: 4vw;
              margin-bottom: 6vw;
            }
          }
        }
      }
    }
  }
}

.the_content{
  h2,h3{
    font-size: 1.5em;
  }
  ul{
    // list-style: circle;
    margin: 1.5em 0;
    padding-left: 40px;
    
  }
  li{
    list-style-type: disc;
    line-height: 2.2;
    @include mq($sm){
      font-size: 4vw;
    }
  }
  p {
    display: block;
    margin:  0 0 1.5em;
    font-size: 1.4vw;
    line-height: 2.2;
    @include mq($sm){
      font-size: 4vw;
    }
  }
  strong { font-weight: bold; }
  em { font-style: italic; }
  blockquote {
      display: block;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 40px;
      -webkit-margin-end: 40px;
  }
  .video{
    position: relative;
    height: 0;
    padding: 30px 0 56.25%;
    overflow: hidden;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width:100%;
      height: 100%;
    }
  }
  
  /* img */
  
  .aligncenter {
      display: block;
      margin: 0 auto;
  }
  .alignright { float: right; }
  .alignleft { float: left; }

  img{
    vertical-align: bottom;
  }
  
  img[class*="wp-image-"],
  img[class*="attachment-"] {
      height: auto;
      max-width: 100%;
  }
}


.second{
  z-index: 9999;
  margin-top: 0;
}

.clone{
  width: 100%;
  // background: #f00;
  position:absolute;
  top:0;
  left:0;
  z-index: -1;
  .contents_area{
    .content{
      width: 60%;
      *{
        color: #fff !important;
        border-color: #FFF;
      }
      
      @include mq($sm){
        width: 84%;
        margin: 0 auto 30vw;
      };
      img{
        opacity: 0;
      }
      .bg{
        background: #fff;
      }
    }
  }
}

.word_area{
  position: relative;
  width: 100%;
  height: 100vh;
  .word{
    // display: inline-block;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);
    line-height: 2.6;
    font-size: 1.4vw;
    @include mq($sm){
      font-size: 3vw;
    }
  }
}

a.recruit{
  display: block;
  border: 2px solid #000;
  text-align: center;
  text-decoration: none;
  
}

footer{
  width:100%;
  position: relative;
  height: 100vh;
  .title{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index:9000;
    @include titlebg;
    background-position: center center;
    background-size:contain;
    background-repeat: no-repeat;
    @include mq($sm){
      background-image:url("/img/image-title-sp.png");
    };
    // position: absolute;
    // top:0;
    // left:0;
    // height: 100vh;
    // width: 100%;
    // text-align:center;
    // img{
    //   height:100%;
    //   width: auto;
    // }
  }
}

@supports (-webkit-touch-callout: none) {
  header {
    .visual{
      height: -webkit-fill-available;
    }

  }
}
